@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .line-clamp-5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Light.ttf');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('./fonts/Lato/Lato-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('./fonts/Lato/Lato-Light.ttf');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('./fonts/Lato/Lato-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

/* --------------------------------------- Start Montserrat --------------------------------- */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Light.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'MontserratMedium';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/Montserrat/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'MontserratBold';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
}
/* --------------------------------------- End Montserrat ----------------------------------- */

/* -------------------------------------------------------------------------- */
@font-face {
  font-family: 'Branding';
  src: url('./fonts/Branding/Branding Light.otf');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'BrandingLight';
  src: url('./fonts/Branding/Branding Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Branding';
  src: url('./fonts/Branding/Branding Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandingMedium';
  src: url('./fonts/Branding/Branding Medium.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BrandingMediumItalic';
  src: url('./fonts/Branding/Branding-MediumItalic.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Branding';
  src: url('./fonts/Branding/Branding Semibold.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Branding';
  src: url('./fonts/Branding/Branding Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Branding';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 400;
  src: url('./fonts/fonts/Branding-Semibold.ttf') format('TrueType');
}

@font-face {
  font-family: 'Branding';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 500;
  src: url('./fonts/fonts/Branding-Bold.ttf') format('TrueType');
}

@font-face {
  font-family: 'BrandingBold';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 500;
  src: url('./fonts/fonts/Branding-Bold.ttf') format('TrueType');
}

@font-face {
  font-family: 'BrandingBoldItalic';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 500;
  src: url('./fonts/fonts/Branding-BoldItalic.ttf') format('TrueType');
}
@font-face {
  font-family: 'BrandingExtraBold';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 700;
  src: url('./fonts/fonts/Branding-Bold.ttf') format('TrueType');
}

@font-face {
  font-family: 'AlmariLight';
  src: url('./fonts/Almarai/Almarai-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AlmariMedium';
  src: url('./fonts/Almarai/Almarai-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AlmariBold';
  src: url('./fonts/Almarai/Almarai-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotcha';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 100;
  src: url('./fonts/fonts/gotcha-standup.ttf') format('TrueType');
}

@font-face {
  font-family: 'Abeezee';
  font-display: auto;
  font-fallback: Serif;
  font-weight: 100;
  src: url('./fonts/fonts/ABeeZee-Regular.ttf') format('TrueType');
}

@font-face {
  font-family: 'HaveHeartOne';
  font-display: auto;
  font-fallback: Serif;
  font-weight: normal;
  src: url('./fonts/Have-Heart-One.otf') format('TrueType');
}

@font-face {
  font-family: 'HelveticaRounded';
  font-display: auto;
  font-fallback: Serif;
  font-weight: normal;
  src: url('./fonts/Helvetica-Rounded.otf') format('TrueType');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  font-family: 'Roboto';
  font-family: 'Roboto';
}

.Toastify *,
body,
html {
  font-family: 'Branding';
}

@media (max-width: 575px) {
  body,
  html {
    overscroll-behavior: none;
  }
}

.copy-right {
  color: #aaa;
  -webkit-text-fill-color: #aaa;
}

select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}

select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* overide */
.react-tel-input .form-control {
  min-height: inherit;
}

/* print page style */

.stage {
  width: 560pt;
  margin: 0 auto;
  background: white;
}

.header {
  height: 110pt;
  margin-bottom: 8pt;
}

.header img {
  width: 100%;
}

.footerImg img {
  width: 100%;
}

.footerImg {
  height: 115pt;
}

.redTable {
  background-color: #d82154;
  height: 72pt;
}

.redTable table {
  color: white;
  width: 100%;
  min-height: 72pt;
}

.whiteTable table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 2px solid black;
}

.whiteTable table td,
.whiteTable table th {
  text-align: center;
}

.whiteTable th {
  padding-top: 20pt;
  padding-bottom: 8pt;
}

.whiteTable td {
  padding-top: 12pt;
  padding-bottom: 12pt;
}

.whiteTable th {
  border-bottom: 2px solid black;
  border-collapse: collapse;
  font-weight: 500;
}

.whiteTable tr:last-child td {
  padding-bottom: 30pt;
}

.whiteTable .whiteTitle {
  font-weight: 500;
  margin-bottom: 8pt;
}

.whiteTable .price {
  color: #d82154;
  font-weight: 500;
}

.whiteTable {
  padding-bottom: 20pt;
}

.footer {
  padding: 16pt;
}

.footer table {
  width: 100%;
}

.footer td {
  width: 50%;
}

.bold {
  font-weight: 500;
}

.thanksImg {
  width: 130pt;
  padding: 38pt 0 18pt;
}

.forDonner {
  font-size: 9pt;
  padding-bottom: 28pt;
}

.left {
  padding-left: 26pt;
  padding-bottom: 16pt;
}

.red {
  color: #d82154;
  /* font-weight: 500; */
}

.center {
  text-align: center;
}

.paddingDown {
  padding-bottom: 12pt;
}

.font10 {
  font-size: 10pt;
}

.gdpr_banner {
  height: 350px;
  background-color: #263477;
  background-image: url(images/others/MATW_logo_2_1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
  padding: 5rem 0rem 5rem 0rem;
}

.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
}

.wpb_single_image .vc_single_image-wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.matwlogo-purpose {
  margin-bottom: -140px;
}

.vc_custom_1599524504655 {
  background-image: url('./images/banners/MATW-Banner-faq.jpg') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.min_height {
  min-height: 650px;
}

.vc_empty_space {
  height: 100px;
}

.vc_empty_space_inner {
}

.heading_charity {
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
}

.charity_p {
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-weight: 400;
  font-style: normal;
}

.nm-divider {
  position: relative;
  min-height: 2px;
}

.nm-divider .nm-divider-line {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  display: block;
  content: ' ';
  width: 100%;
  height: 1px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #eee;
}

.custom_heading {
  color: #ffffff;
  font-size: 22px;
  text-align: center;
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
}

.custom_heading_p {
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
  font-size: 22px;
}

.vc_custom_heading_africa {
  color: #dd3333;
  text-align: left;
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
  font-size: 18px;
}

.custom_acc_heading {
  font-size: 24px;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400;
}

.accord_p a {
  color: rgb(0, 170, 255);
}

.accord_p p {
  margin: 10px;
}

.accord_p ol li {
  padding-left: 10px;
  list-style-type: decimal;
}

.accord_p ol li,
.wpb_text_column ul li {
  list-style-position: inside;
}

.vc_custom_heading_policy {
  color: #ffffff;
  text-align: center;
  font-family: Raleway;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 24px;
}

.vc_custom_1599524532673 {
  padding-right: 45px !important;
  padding-left: 45px !important;
  background-image: url('./images/banners/MATW-Home-Banner-Donation-Policy.jpg') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.faq_heading {
  color: #23408f;
  text-align: left;
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
  font-size: 28px;
}

.font-roboto {
  font-family: Roboto;
}

body {
  scroll-behavior: smooth;
  transition: height 1s ease-in-out;
}

.feature-box img {
  width: 30%;
}

.banner-content .box {
  background-color: rgb(0 176 236/25%);
  padding: 70px;
  border-radius: 40px;
}

.section-heading h1 {
  font-family: epilogue, sans-serif;
  font-size: 44px;
  font-weight: 800;
  line-height: 54px;
  margin-bottom: 0;
  color: #3f3f40;
}

.section-heading h1 span {
  font-family: epilogue, sans-serif;
  color: #00b1ed;
}

.section-heading h1 + p {
  font-family: epilogue, sans-serif;
  margin-top: 24px;
}

.section-heading p {
  font-family: epilogue, sans-serif;
  margin-bottom: 0;
  color: #646464;
}

.section-heading p + p {
  font-family: epilogue, sans-serif;
  margin-top: 24px;
}

.banner-content .box .btn-sec {
  display: flex;
}

.banner-content .box .btn-sec a {
  display: inline-block;
}

.banner-content .box .btn-sec a img {
  max-width: 100%;
  height: 50px;
}

.section-heading p + .btn-sec {
  margin-top: 50px;
}

.static-one .section-heading {
  display: inline-block;
  max-width: 850px;
}

.section-heading h1 {
  font-size: 44px;
  font-weight: 800;
  line-height: 54px;
  margin-bottom: 0;
  color: #3f3f40;
}

.static-one .section-heading p {
  display: inline-block;
  max-width: 680px;
}

.section-heading h1 + p {
  margin-top: 24px;
}

.section-heading p {
  margin-bottom: 0;
  color: #646464;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-main {
  color: #fff;
  background-color: #00b1ed;
  border-color: #00b1ed;
}

.btn-350 {
  width: 100%;
  max-width: 350px;
}

.featured-program .section-heading p {
  max-width: 700px;
}

.featured-program .feature-box {
  margin-top: 50px;
}

.feature-box img {
  width: 30%;
}

.feature-box h4 {
  font-family: epilogue, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #de2c46;
  margin-top: 28px;
  margin-bottom: 12px;
}

.feature-box p {
  font-family: epilogue, sans-serif;
  margin-bottom: 0;
}

.get-spread {
  background-color: #be083ad9;
  padding: 70px 0;
  margin-top: 100px;
}

.get-spread .img-sec {
  position: absolute;
  bottom: 0;
  left: 0;
}

.get-spread .section-heading {
  margin-bottom: 50px;
}

.get-spread .section-heading h1 {
  color: #fff;
}

.get-spread .section-heading p {
  color: #fff;
}

.get-spread .app-download {
  display: flex;
}

.get-spread .app-download a {
  display: inline-block;
}

@media (max-width: 767px) {
  .banner-content .box {
    padding: 50px 15px;
    border-radius: 0;
    margin: 0 -15px;
  }

  .section-heading h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .get-spread .section-heading {
    margin-bottom: 24px;
  }

  .featured-program .feature-box {
    margin-top: 24px;
  }

  .feature-box img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .get-spread .img-sec {
    position: relative;
    margin-top: 30px;
  }

  .get-spread {
    margin-top: 20px;
    padding: 0px;
  }

  .custom_heading {
    font-weight: 700;
    font-size: 15px;
  }

  .custom_acc_heading {
    font-size: 12px;
  }
}

#carousel .carousel-root {
  position: relative;
}

#carousel .carousel-root > .carousel:nth-child(2) {
  position: absolute;
  bottom: 0;
  background: #00000069;
}

#carousel .carousel-root > .carousel:nth-child(2) .thumbs-wrapper ul {
  display: flex;
  justify-content: center;
}

.orphan_select__control,
.react-select__control {
  border: none !important;
}

.react-select__control .react-select__value-container {
  display: none;
}

.react-select__control .react-select__indicator-separator {
  display: none;
}

.react-select__control .react-select__indicators .react-select__dropdown-indicator {
  color: #f60362;
}

.react-select__menu .react-select__option {
  padding: 4px !important;
}

.react-select__menu .react-select__option {
  padding: 4px !important;
}

/* test widget */
.react-widget__control {
  border: none !important;
}

.react-widget__control .react-widget__value-container {
  /* display: none; */
}

.react-widget__control .react-widget__indicator-separator {
  display: none;
}

.react-widget__control {
  outline: none;
  box-shadow: none !important;
}

.react-widget__control .react-widget__indicators .react-widget__dropdown-indicator {
  color: #f60362;
}

.react-widget__menu .react-widget__option {
  padding: 4px !important;
}

.react-widget__menu .react-widget__option {
  padding: 4px !important;
}

.orphan_select__control {
  height: 48px;
}

.lylatul-qadr__control {
  height: 95px;
  border: 0;
  font-size: 24px;
}
.react-datepicker__navigation--previous {
  left: 175px !important;
}
.react-datepicker__current-month {
  text-align: left;
  padding-left: 15px;
}
@media (max-width: 575px) {
  .lylatul-qadr__control {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .lylatul-qadr__control {
    height: 60px;
  }
}

.lylatul-qadr__indicator-separator {
  width: 0px;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker {
  font-family: 'Branding' !important;
  background-color: white !important;
  border: 0px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  /* font-size: 24px !important; */
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 0px !important;
}

.react-datepicker__day-name {
  color: #6c6c6c !important;
}

.react-datepicker__day-names {
  margin-top: 8px;
}

/* .react-datepicker__day{
  margin: 0 !important;
  padding: .5rem .5rem;
  width: 2rem !important;
  /* border-radius: 50% !important; */
/* line-height: 1rem !important; */
/* } */
/* .react-datepicker__day--range-start,.react-datepicker__day--range-end{
  background-color: #01A0D8 !important;
  color: white !important;
  border-radius: 50% !important; */

/* } */

/* .react-datepicker__day--range-start::after{
  background-color: #01A0D8 !important;
content: '';
width: 13px;
height: 100%;
position: absolute;
top: 0;
  right: -1px;
  /* opacity: .5; */

.react-datepicker__day--in-range {
  background-color: #01a0d8 !important;
  /* opacity: .5; */
  position: relative;
}

.orphan-widget__control {
  border: none !important;
  box-shadow: none !important;
  height: 48px;
}

.blob.red {
  background: rgba(246, 3, 98, 1);
  box-shadow: 0 0 0 0 rgba(246, 3, 98, 1);
  transform: scale(1);
  color: white;
  animation: pulse-red 2s infinite;
}
.blob.red:disabled {
  animation: none;
}

.circle_animation:hover {
  animation: pulse-red 2s infinite;
}

.subscription_animation:hover {
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-red {
  0% {
    /* transform: scale(0.95); */
    color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    /* transform: scale(1); */
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    /* transform: scale(0.95); */
    color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-blue {
  0% {
    /* transform: scale(0.95); */
    color: rgba(0, 163, 218, 0.7);
    box-shadow: 0 0 0 0 rgba(0, 163, 218, 0.7);
  }

  70% {
    /* transform: scale(1); */
    color: rgba(0, 163, 218, 1);
    box-shadow: 0 0 0 20px rgba(0, 163, 218, 0);
  }

  100% {
    /* transform: scale(0.95); */
    color: rgba(0, 163, 218, 0.7);
    box-shadow: 0 0 0 0 rgba(0, 163, 218, 0);
  }
}

.tooltip {
  position: relative;
  /* display: inline-block; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  /* visibility: visible; */
  opacity: 1;
}

/* [1] The container */
.img-hover-zoom {
  /* height: 300px;  */
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: all 0.3s ease !important;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.3);
}

.pay-modal_top {
  display: flex;
  justify-content: center;
}

.pay-modal_content .section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-modal_inner .loaderHolder,
.pay-modal_inner .loaderHolder .loader.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  transition: all 0.5s ease-in-out;
  height: 0;
}

.navbar.open {
  height: 38rem;
  overflow-y: scroll;
}

.home_select__control,
.react-select__control {
  border: none !important;
}
.home-widget__control {
  border: 1px solid hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
  padding: 0;
}
@media (max-width: 767px) {
  .home_select__indicator {
    padding: 2px !important;
  }
  .home_select__single-value {
    font-size: 12px;
  }
}
.circle_animation-zakat {
  animation: pulse-red 2s infinite;
}

.admin-cost .css-1dimb5e-singleValue {
  color: #777 !important;
}

*,
html {
  scroll-behavior: smooth !important;
}
.weglot-container {
  display: flex !important;
  align-items: center;
}
.weglot-container .weglot_switcher {
  padding: 0px !important;
}
